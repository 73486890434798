import React from "react"

export function Like(props) {
    return (
        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14.6647 2.14171C14.3029 1.77975 13.8733 1.49263 13.4005 1.29673C12.9278 1.10083 12.421 1 11.9092 1C11.3975 1 10.8907 1.10083 10.418 1.29673C9.94517 1.49263 9.51562 1.77975 9.15383 2.14171L8.403 2.89254L7.65216 2.14171C6.92138 1.41093 5.93023 1.00038 4.89675 1.00038C3.86327 1.00038 2.87211 1.41093 2.14133 2.14171C1.41055 2.87249 1 3.86364 1 4.89713C1 5.93061 1.41055 6.92176 2.14133 7.65254L2.89216 8.40338L8.403 13.9142L13.9138 8.40338L14.6647 7.65254C15.0266 7.29076 15.3137 6.8612 15.5096 6.38842C15.7055 5.91564 15.8064 5.40889 15.8064 4.89713C15.8064 4.38536 15.7055 3.87862 15.5096 3.40583C15.3137 2.93305 15.0266 2.5035 14.6647 2.14171Z"
                stroke="#CC78FF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
