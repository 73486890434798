"use client"
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react"
import { usePathname } from "next/navigation"

const Context = createContext<string[]>([])

export function RouterHistory({ children }: { children: ReactNode }) {
    const pathname = usePathname()
    const [history, setHistory] = useState(() => [pathname])
    useEffect(() => {
        setHistory((s) => [...s, pathname])
    }, [pathname])
    return <Context.Provider value={history}>{children}</Context.Provider>
}

export const useHistory = () => {
    const history = useContext(Context)
    return {
        history,
        getLast: useCallback(() => history[history.length - 2], [history])
    }
}
