import { createContext, useContext } from "react"

export type User = {
    username?: string
    first_name?: string
    emails?: string[]
}
export type Store = {
    isMobile: boolean
    loginModalVisible: boolean
    favorites: Record<string, any>
    searches?: any[]
    user: User
    loading: boolean
}
export const storeContext = createContext<Store>({
    isMobile: false,
    loginModalVisible: false,
    favorites: {},
    searches: undefined,
    user: undefined,
    loading: true
})

export function useStore() {
    return useContext(storeContext)
}
