export function Mail() {
    return (
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.95655 0H14.0468C15.126 0 16.0033 0.848215 16 1.88776V9.84375C16 10.8833 15.1227 11.7283 14.0434 11.7283H1.95655C0.877301 11.7283 0 10.8833 0 9.84375V1.88457C0 0.845026 0.877301 0 1.95655 0ZM14.0459 10.8675C14.6319 10.8675 15.1086 10.4083 15.1086 9.84387H15.1053V1.88787C15.1053 1.32346 14.6286 0.864273 14.0426 0.864273H1.95569C1.36972 0.864273 0.893 1.32346 0.893 1.88787V9.84387C0.893 10.4083 1.36972 10.8675 1.95569 10.8675H14.0459Z"
                fill="white"
            />
            <path
                d="M10.0906 5.76552L14.0037 2.38542C14.1858 2.22598 14.2023 1.95493 14.0368 1.77636C13.8713 1.60098 13.5899 1.58503 13.4045 1.74447L8.00825 6.40966L6.95549 5.50404C6.95218 5.50085 6.94887 5.49766 6.94887 5.49448C6.9257 5.47215 6.90252 5.45302 6.87604 5.43389L2.59216 1.74128C2.40677 1.58185 2.12537 1.59779 1.95984 1.77636C1.79431 1.95493 1.81086 2.22598 1.99626 2.38542L5.9557 5.79422L2.01281 9.34971C1.83404 9.51234 1.82411 9.78338 1.99294 9.95877C2.08233 10.0481 2.20151 10.0959 2.32069 10.0959C2.42994 10.0959 2.53919 10.0576 2.62526 9.98109L6.62774 6.37458L7.71361 7.30889C7.79969 7.38223 7.90562 7.41731 8.01156 7.41731C8.1175 7.41731 8.22675 7.37904 8.30951 7.3057L9.42518 6.34269L13.4045 9.98427C13.4906 10.064 13.6031 10.1023 13.7124 10.1023C13.8315 10.1023 13.9474 10.0576 14.0368 9.96833C14.2056 9.79614 14.199 9.5219 14.0202 9.35927L10.0906 5.76552Z"
                fill="white"
            />
        </svg>
    )
}
