import styles from "@css/modules/Listings.module.scss"
import React from "react"
import { isBrowser } from "@utils/const"
import moment from "moment"
import { keys, store } from "@utils/store"
import cn from "classnames"

moment.updateLocale("en", {
    relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "a few seconds",
        ss: "%d seconds",
        m: "1 minute",
        mm: "%d minutes",
        h: "1 hour",
        hh: "%d hours",
        d: "1 day",
        dd: "%d days",
        w: "1 week",
        ww: "%d weeks",
        M: "1 month",
        MM: "%d months",
        y: "1 year",
        yy: "%d years"
    }
})

export function renderPoints(listing = {}, years) {
    let points = listing.points
    return points ? years.map((year, i) => (points[year] === undefined ? (i === 0 ? "0" : `${listing.points_on_contract}`) : points[year])).join(" | ") : ""
}

export const likeStorageKey = "likeStorageKey"

export const updateListingLike = (item) => {
    if (isBrowser) {
        const currentValue = localStorage.getItem(likeStorageKey) || "{}"
        const val = JSON.parse(currentValue)
        val[item.id] = item.like ? Date.now() : 0
        Object.keys(val).forEach((i) => {
            if (!val[i]) {
                delete val[i]
            }
        })
        store.set(keys.favorites, val)
        gtag?.("event", "favoriteUse")
        localStorage.setItem(likeStorageKey, JSON.stringify(val))
    }
}

const statusMap = {
    default: {
        color: "#ffffff"
    },
    "Double Points": {
        color: "#FFE4A0",
        text: "Double Pts"
    },
    get "Nearly Double Points"() {
        return this["Double Points"]
    },
    New: {
        color: "#FF52E3"
    },
    "Small Contract": {
        color: "#52B6FF"
    },
    "Extended to 2057": {
        text: "2057 Deed"
    },
    Available: {
        color: "#38FFAB"
    }
}
export const renderStatus = (listing, cls) => {
    const { color, text } = statusMap[listing.status] || statusMap.default
    return (
        <span className={cn(styles.statusBtn, cls)} title={listing.status} style={{ "--statusColor": color || statusMap.default.color }}>
            {text || listing.status}
        </span>
    )
}

export const addLikes = (items) => {
    const stringLikes = isBrowser && localStorage.getItem(likeStorageKey)
    if (stringLikes) {
        const likes = JSON.parse(stringLikes)
        items.forEach((i) => {
            i.like = !!likes[i.id]
            i.key = i.id + Math.random().toString()
            return i
        })
    }
    return items
}

export function getDays(createdAt) {
    const days = moment().diff(moment(createdAt), "days")
    const suffix = days === 1 ? " day" : " days"
    return (
        <span title={days + suffix}>
            {days}
            {suffix}
        </span>
    )
}

export function getTotalPrice(listing) {
    let total = listing.points_on_contract * listing.price_per_point
    if (listing.dues_reimbursement) {
        total += listing.dues_reimbursement
    }
    if (listing.closing_cost) {
        total += listing.closing_cost
    }
    return total
}

export function getDirectPrice(listing) {
    return listing.resort.directPrice * listing.points_on_contract
}

export const originImgURL = {
    atimeshare: "/images/listings-brokers/atimeshare.png",
    dvcadvantages: "/images/listings-brokers/dvcadvantages.png",
    // dvctimeshares: "/images/listings-brokers/dvctimeshares.jpg",
    vacatia: "/images/listings-brokers/vacatia.jpg",
    buyandselldvc: "/images/listings-brokers/buyandselldvc.jpg",
    dvcbyresale: "/images/listings-brokers/dvcbyresale.jpg",
    dvcresaleexperts: "/images/listings-brokers/dvcresaleexperts.png",
    dvcsales: "/images/listings-brokers/dvcsales.jpg",
    resalesdvc: "/images/listings-brokers/resalesdvc.png",
    dvcresalemarket: "/images/listings-brokers/dvcresalemarket.png",
    dvcshop: "/images/listings-brokers/dvcshop.png",
    dvcstore: "/images/listings-brokers/dvcstore.png",
    fidelityresales: "/images/listings-brokers/fidelity.png"
}
