import React, { createContext, useContext, useEffect, useState } from "react"
import { fetchRest } from "@lib/fetchRest"

const FeatureFlagContext = createContext()

// type UserContext = {
//     kind: "user"
//     key?: string
//     anonymous?: true
//     name?: string
//     email?: string
//     [additional: string]: any
// }

export function FeatureFlagProvider({ children, context, clientSideID, options = {} }) {
    const [flags, setFlags] = useState({})

    useEffect(() => {
        const fetchFlags = async () => {
            const { data } = await fetchRest(
                "feature-flags",
                {
                    fields: ["id", "featureName"],
                    populate: {
                        enabledUsernames: {
                            fields: ["value"]
                        }
                    },
                    filters: {
                        $or: [
                            {
                                enabledForAll: {
                                    $eq: true
                                }
                            },
                            {
                                enabledUsernames: {
                                    value: {
                                        $eq: context.key ?? "anonymous"
                                    }
                                }
                            }
                        ]
                    },
                    pagination: {
                        limit: 100
                    }
                    // sort: "createdAt:asc"
                },
                undefined,
                1800
            ) // 30 min cache

            const fetchedFlags = {}
            if (Array.isArray(data)) {
                data.forEach((item) => {
                    if (item && typeof item.featureName === "string") {
                        fetchedFlags[item.featureName] = true
                    }
                })
            }

            setFlags(fetchedFlags)

            if (options.bootstrap === "localStorage") {
                try {
                    JSON.parse(JSON.stringify(fetchedFlags))
                    localStorage.setItem("featureFlags", JSON.stringify(fetchedFlags))
                } catch (error) {
                    console.error("Invalid JSON in fetchedFlags:", error)
                }
            }
        }

        if (options.bootstrap === "localStorage") {
            const cachedFlags = JSON.parse(localStorage.getItem("featureFlags") || "{}")
            setFlags(cachedFlags)
        }

        if (options.bootstrap !== "eventsOnly") {
            fetchFlags()
        }

        if (options.streaming) {
            const interval = setInterval(fetchFlags, 30000)
            return () => clearInterval(interval)
        }
    }, [context, clientSideID, options])

    return <FeatureFlagContext.Provider value={flags || {}}>{children}</FeatureFlagContext.Provider>
}

export function useFlags() {
    return useContext(FeatureFlagContext)
}
