export function Facebook() {
    return (
        <svg width="20" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.24 0.5H0.76C0.339625 0.5 0 0.839625 0 1.26V18.74C0 19.1604 0.339625 19.5 0.76 19.5H18.24C18.6604 19.5 19 19.1604 19 18.74V1.26C19 0.839625 18.6604 0.5 18.24 0.5ZM17.48 17.98H13.1076V12.1423H15.5776L15.9481 9.27563H13.1076V7.4445C13.1076 6.61325 13.338 6.048 14.5279 6.048H16.0455V3.483C15.7819 3.44738 14.8818 3.369 13.832 3.369C11.6423 3.369 10.1436 4.70612 10.1436 7.1595V9.27325H7.66887V12.1399H10.146V17.98H1.52V2.02H17.48V17.98Z"
                fill="#CFCFCF"
            />
        </svg>
    )
}
