import styles from "@css/modules/Layout.module.scss"
import Link from "next/link"
import React from "react"

import Image from "next/image"
import { Insta } from "@css/icons/Insta"
import { Facebook } from "@css/icons/Facebook"
import { X } from "@css/icons/X"

const footerLinks = [
    /*    {
        title: "Company",
        links: [
            {
                title: "About",
                link: "/about-us"
            },
            {
                title: "Blog",
                link: "/blog"
            },
            {
                title: "Contact us",
                link: "/contact-us"
            }
        ]
    },*/
    {
        title: "legal",
        links: [
            {
                title: "Terms & Conditions",
                link: "/terms"
            },
            {
                title: "Privacy policy",
                link: "/privacy"
            } /*
            {
                title: "Legal Information",
                link: "/legal"
            }*/
        ]
    } /*
    {
        title: "Explore",
        links: [
            {
                title: "How it works?",
                link: "/how-it-works"
            },
            {
                title: "Help",
                link: "/help"
            },
            {
                title: "FAQs",
                link: "/faq"
            }
        ]
    }*/
]

export function FooterComponent() {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerContent}>
                <div className={styles.footerTopLevel}>
                    <nav className={styles.footerLinks}>
                        {footerLinks.map((i) => (
                            <div key={i.title} className={styles.footerLinksGroup}>
                                <p>{i.title}</p>
                                {i.links.map((j) => (
                                    <Link href={j.link} key={j.title}>
                                        {j.title}
                                    </Link>
                                ))}
                            </div>
                        ))}
                    </nav>
                    <div className={styles.footerTopLevelRight}>
                        <Link href="/">
                            <Image src="/images/bigLogo.png" alt="DVC FOR LESS logo" width="138" height="96" />
                        </Link>
                        <p className={styles.footerTopLevelRightText}>
                            DVC For Less, LLC is not in any way an agent of, sponsored by, affiliated with, or in any way officially connected with the Walt Disney Company, or any
                            of its affiliates, subsidiaries, or other associated businesses. The official Walt Disney Company website can be found at www.thewaltdisneycompany.com
                        </p>
                    </div>
                </div>
                <div className={styles.footerBottomLevel}>
                    <div className={styles.footerBottomLevelSocial}>
                        <a href="https://www.instagram.com/dvcforless" target="_blank">
                            <Insta /> instagram
                        </a>
                        <a href="https://www.facebook.com/dvcforless" target="_blank">
                            <Facebook /> facebook
                        </a>
                        <a href="https://twitter.com/dvcforless" target="_blank">
                            <X /> X / twitter
                        </a>
                    </div>
                    <span className={styles.footerBottomLevelRights}>© DVC FOR LESS {new Date().getFullYear()} All Rights Reserved.</span>
                </div>
            </div>
        </footer>
    )
}
