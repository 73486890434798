"use client"
import React, { useEffect } from "react"
import Script from "next/script"
import { usePathname } from "next/navigation"

const GOOGLE_TAG_GTM = "GTM-NT3PXVH"

const content = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
             new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
             j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
             'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
         })(window,document,'script','dataLayer','${GOOGLE_TAG_GTM}');`

export const GAPageView = () => {
    const pathname = usePathname()
    useEffect(() => {
        window.dataLayer = window.dataLayer || []
        window.gtag = function gtag() {
            dataLayer.push(arguments)
        }
        gtag("js", new Date())

        gtag("config", GOOGLE_TAG_GTM, {
            send_page_view: false
        })
    }, [])
    useEffect(() => {
        gtag("set", "page_path", pathname)
        gtag("event", "page_view")
    }, [pathname])
    return <Script id="g-tag-script" strategy="afterInteractive" children={content} />
}
