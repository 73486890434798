"use client"
import { useEffect } from "react"

export const Clarity = () => {
    useEffect(() => {
        if (process.env.CLARITY_ID) {
            ;(function (c, l, a, r, i) {
                let t, y
                c[a] =
                    c[a] ||
                    function () {
                        ;(c[a].q = c[a].q || []).push(arguments)
                    }
                t = l.createElement(r)
                t.async = 1
                t.src = "https://www.clarity.ms/tag/" + i
                y = l.getElementsByTagName(r)[0]
                y.parentNode.insertBefore(t, y)
            })(window, document, "clarity", "script", process.env.CLARITY_ID)
        }
    }, [])
    return null
}
