import { Listing } from "@t/Listing"

export function listingsSlug(id: number, short_name: string) {
    return short_name.toLowerCase().replace(/\s|&/g, "-").replace(/---/, "-") + `_${id}`
}

export const getListingUrl = (params: { id: number; resort?: { short_name?: string } }): string => {
    const { id, resort } = params
    const short_name = (resort && resort.short_name) || ""
    return "/listings/" + listingsSlug(id, short_name)
}

/*export async function listingChildStaticProps({ params }) {
    const notFound = { notFound: true, revalidate: staticRevalidateTime }
    let id
    try {
        id = params?.id?.split("_")[1]
    } catch (err) {
        console.log(err)
        return { notFound: true }
    }
    if (!id) {
        console.error("\n no query ")
        return notFound
    }
    const [data, gd] = await Promise.all([getListingById(id), generalData()])

    if (data) {
        return {
            props: {
                gd,
                data
            },
            revalidate: staticRevalidateTime
        }
    } else {
        console.log("\n MISSING LISTING WITH ID ", id)
        return notFound
    }
}*/

export function getPointMinMax(listing: Pick<Listing, "points_on_contract">) {
    // WHEN points_on_contract <= 75 THEN '1-75'
    // WHEN points_on_contract <= 125 THEN '76-125'
    // WHEN points_on_contract <= 199 THEN '126-199'
    // WHEN points_on_contract <= 349 THEN '200-349'
    // ELSE '350 and over'
    let pointsMin = 0
    let pointsMax = 0
    if (listing?.points_on_contract) {
        if (listing?.points_on_contract <= 75) {
            pointsMin = 1
            pointsMax = 75
        } else if (listing?.points_on_contract <= 125) {
            pointsMin = 76
            pointsMax = 125
        } else if (listing?.points_on_contract <= 199) {
            pointsMin = 126
            pointsMax = 199
        } else if (listing?.points_on_contract <= 349) {
            pointsMin = 200
            pointsMax = 349
        } else {
            pointsMin = 350
            pointsMax = 9999
        }
    }
    return {
        pointsMin,
        pointsMax
    }
}

export function getListingIdFromSlug(id?: string) {
    try {
        return id?.split("_")[1]
    } catch (err) {
        console.log(err)
        return ""
    }
}
