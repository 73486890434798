"use client"
import React, { useEffect, useState } from "react"
import SuperTokensReact, { SuperTokensWrapper } from "supertokens-auth-react"
import { frontendConfig, setRouter } from "config/frontendConfig"
import { usePathname, useRouter } from "next/navigation"
import { store } from "@utils/store"
import { isTouchDevice } from "@utils/device"
import { UAParser } from "ua-parser-js"
import { fetchRest } from "@lib/fetchRest"
import { SWRConfig } from "swr"
import { FeatureFlagWrapper } from "@components/FeatureFlagWrapper"

if (process.env.ENV !== "PROD") {
    import("../utils/devFetchInterceptor")
}

if (typeof window !== "undefined") {
    // we only want to call this init function on the frontend, so we check typeof window !== 'undefined'
    SuperTokensReact.init(frontendConfig())
}
function getClasses(className: string) {
    const isTouch = isTouchDevice()
    const cn: string[] = [className || ""]
    if (isTouch) {
        cn.push("touch-device")
    }
    const parser = new UAParser().getResult()
    const os = "os-" + parser.os?.name?.toLowerCase()?.replace(/\s/g, "_")
    const browser = "browser-" + parser.browser?.name?.toLowerCase()?.replace(/\s/g, "_")
    cn.push(os, browser)
    return cn
}
const swrConfig = {
    fetcher: (url: string) => fetchRest(url)
}
const klaviyoUrl = `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.NEXT_PUBLIC_KLAVIYO_SITE_ID}`

export const Providers: React.FC<React.PropsWithChildren<{ className: string }>> = ({ children, className }) => {
    const pathname = usePathname()
    setRouter(useRouter(), pathname || window.location.pathname, typeof window !== "undefined" ? new URLSearchParams(window.location.search) : new URLSearchParams())
    const [cn, setCN] = useState<string>(() => className)
    const scriptTargetRef = React.useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handler = () => store.set("isMobile", !!mediaQuery.matches)

        const mediaQuery = window.matchMedia("(max-width: 992px)")

        if (mediaQuery.addEventListener) {
            mediaQuery.addEventListener("change", handler)
        } else {
            mediaQuery.addListener(handler)
        }
        handler()

        setCN(getClasses(className).join(" "))
        /*      setTimeout(() => {
            const sc = document.createElement("script")
            sc.src = "//static.klaviyo.com/onsite/js/klaviyo.js?company_id=T2WxB9"
            document.body.appendChild(sc)
        }, 3000)*/
        return () => {
            if (mediaQuery.removeEventListener) {
                mediaQuery.removeEventListener("change", handler)
            } else {
                mediaQuery.removeListener(handler)
            }
        }
    }, [className])
    return (
        <body className={cn}>
            <SWRConfig value={swrConfig}>
                <SuperTokensWrapper>
                    <FeatureFlagWrapper>{children}</FeatureFlagWrapper>
                </SuperTokensWrapper>
            </SWRConfig>
            <div ref={scriptTargetRef} />
            <script
                dangerouslySetInnerHTML={{
                    __html: '!function(){if(!window.klaviyo){window._klOnsite=window._klOnsite||[];try{window.klaviyo=new Proxy({},{get:function(n,i){return"push"===i?function(){var n;(n=window._klOnsite).push.apply(n,arguments)}:function(){for(var n=arguments.length,o=new Array(n),w=0;w<n;w++)o[w]=arguments[w];var t="function"==typeof o[o.length-1]?o.pop():void 0,e=new Promise((function(n){window._klOnsite.push([i].concat(o,[function(i){t&&t(i),n(i)}]))}));return e}}})}catch(n){window.klaviyo=window.klaviyo||[],window.klaviyo.push=function(){var n;(n=window._klOnsite).push.apply(n,arguments)}}}}();'
                }}
            />
            <script src={klaviyoUrl} async />
        </body>
    )
}
