import EmailVerification from "supertokens-auth-react/recipe/emailverification"
import ThirdPartyReact from "supertokens-auth-react/recipe/thirdparty"
import EmailPasswordReact from "supertokens-auth-react/recipe/emailpassword"
import SessionReact from "supertokens-auth-react/recipe/session"
import { appInfo } from "./appInfo"
import { useRouter } from "next/navigation"
import { SuperTokensConfig } from "supertokens-auth-react/lib/build/types"

const routerInfo: { router?: ReturnType<typeof useRouter>; pathName?: string; search?: URLSearchParams } = {}

export function setRouter(router: ReturnType<typeof useRouter>, pathName: string, search: URLSearchParams) {
    routerInfo.router = router
    routerInfo.pathName = pathName
    routerInfo.search = search
}

export const frontendConfig = (): SuperTokensConfig => {
    return {
        enableDebugLogs: false,
        appInfo,
        recipeList: [
            EmailVerification.init({
                mode: "OPTIONAL" // or "REQUIRED"
            }),
            ThirdPartyReact.init({
                signInAndUpFeature: {
                    providers: [
                        ThirdPartyReact.Google.init(),
                        ThirdPartyReact.Apple.init()
                        //ThirdPartyReact.Facebook.init()
                    ]
                },
                override: {
                    functions: (oI) => {
                        return {
                            ...oI,
                            getAuthStateFromURL: (input) => {
                                if (input.userContext.state) {
                                    return input.userContext.state
                                }
                                return oI.getAuthStateFromURL(input)
                            }
                        }
                    }
                },
                preAPIHook: async (context) => {
                    let url = context.url

                    // is the fetch config object that contains the header, body etc..
                    let requestInit = context.requestInit

                    let action = context.action
                    // if (action === "EMAIL_EXISTS") {
                    // } else if (action === "GET_AUTHORISATION_URL") {
                    // } else if (action === "SEND_RESET_PASSWORD_EMAIL") {
                    // } else if (action === "EMAIL_PASSWORD_SIGN_IN") {
                    // } else if (action === "EMAIL_PASSWORD_SIGN_UP") {
                    // } else
                    if (action === "THIRD_PARTY_SIGN_IN_UP") {
                        // Note: this could either be sign in or sign up.
                        // we don't know that at the time of the API call
                        // since all we have is the authorisation code from
                        // the social provider

                        if (typeof requestInit.body !== "string") {
                            throw new Error("should not happen")
                        }
                        let body = JSON.parse(requestInit.body)

                        // if google, and has an id_token, lets set it. If not, assume normal auth flow
                        if (body && body?.thirdPartyId === "google" && context.userContext?.id_token) {
                            // console.log(`body: ${JSON.stringify(body)}`)
                            // console.log(`context: ${JSON.stringify(context)}`)
                            body.redirectURIInfo = undefined
                            body.oAuthTokens = { id_token: context.userContext.id_token }
                        }

                        context.requestInit.body = JSON.stringify(body)

                        // let body = JSON.parse(requestInit?.body)
                        // if (body && body?.thirdPartyId === "apple" && Router?.query?.user) {
                        //     try {
                        //         let user = JSON.parse(Router.query.user)
                        //         requestInit.body = JSON.stringify({ ...body, user })
                        //     } catch {
                        //         console.log("catch apple callback issue")
                        //     }
                        // }
                    }
                    // else if (action === "SUBMIT_NEW_PASSWORD") {
                    // } else if (action === "VERIFY_EMAIL") {
                    // }

                    // events such as sign out are in the
                    // session recipe pre API hook (See the info box below)

                    return context
                }
            }),
            EmailPasswordReact.init(),
            SessionReact.init()
        ],
        windowHandler: (oI) => {
            return {
                ...oI,
                location: {
                    ...oI.location,
                    getPathName: () => routerInfo.pathName!,
                    assign: (url) => routerInfo.router!.push(url.toString()),
                    setHref: (url) => routerInfo.router!.push(url.toString())
                }
            }
        }
    }
}
