import { fetchRest } from "@lib/fetchRest"
import { Resort } from "@t/Resort"

export function filterResorts(resorts: Resort[] = []) {
    return resorts.filter((i) => i.is_visible !== false).sort((a, b) => (a.short_name || "").localeCompare(b.short_name || ""))
}

/*export async function getResortsList() {
    const data = await fetchRestAPI("resorts", {
        fields: ["id", "is_visible", "short_name"],
        pagination: {
            limit: 50
        },
        publicationState: "live"
    })
    return filterResorts(data?.resorts)
}*/

export async function getResortsForGuide() {
    const { data } = await fetchRest<Resort[]>("resorts", {
        fields: ["id", "slug", "short_name", "short_description", "description", "is_visible"],
        sort: "short_name:asc",
        populate: ["logo"],
        pagination: {
            limit: 50
        }
    })

    return filterResorts(data)
}

export async function getAllResortsWithSlug() {
    const { data } = await fetchRest<Resort[]>("resorts", {
        fields: ["slug", "updatedAt", "is_visible"],
        pagination: {
            limit: 50
        }
    })
    return filterResorts(data)
}

export async function getAllResorts() {
    const { data } = await fetchRest<Resort[]>("resorts", {
        filters: { is_visible: { $eq: true } },
        fields: ["id", "is_visible", "short_name", "slug", "deed_expiration", "short_description", "description", "annual_dues_per_point"],
        sort: "short_name:asc",
        populate: ["logo", "background"],
        pagination: {
            limit: 50
        }
    })
    return filterResorts(data)
}

export async function getPointChartsBySlug(slug: string, year?: number) {
    const { data } = await fetchRest<any[]>("point-charts", {
        filters: { resort: { slug: { $eq: slug } }, year: { $eq: year } },
        populate: {
            rows: {
                populate: {
                    cells: 1
                }
            },
            headers: 1,
            column_description: 1,
            periods: {
                populate: {
                    item: 1
                }
            }
        }
    })
    return data || []
}

export async function getPointChartData(resortId: string, signal: AbortSignal) {
    const { data } = await fetchRest(
        "point-charts",
        {
            filters: { resort: { id: { $eq: resortId } } },
            populate: {
                rows: {
                    populate: {
                        cells: 1
                    }
                },
                headers: 1,
                column_description: 1,
                periods: {
                    populate: {
                        item: 1
                    }
                }
            }
        },
        signal,
        60 * 60 * 24
    )
    return data || []
}

export const resortPopulate = {
    resort_due: 1,
    valueGrade: {
        populate: {
            valueGradeProps: {
                populate: ["icon"]
            }
        }
    },
    price_updates: 1,
    logo: 1,
    background: 1,
    transportation: {
        populate: ["image"]
    },
    reviews: {
        populate: ["review_image"]
    },
    featuredActivities: {
        populate: ["background", "feature_image"]
    },
    apartments: 1,
    restaurant: {
        populate: {
            restaurantsHead: {
                populate: ["background"]
            },
            diningLocations: {
                populate: {
                    diningItems: {
                        populate: ["gallery"]
                    }
                }
            }
        }
    },
    offer: 1
}

export async function getResortBySlug(slug: string) {
    const { data: resorts } = await fetchRest<[Resort]>("resorts", {
        filters: {
            slug: { $eq: slug }
        },
        pagination: {
            limit: 1
        },
        populate: {
            ...resortPopulate,
            partner: {
                ...resortPopulate
            }
        }
    })
    return resorts[0]
}

export const resortSeoPopulate = {
    seo: {
        fields: ["title", "description", "keywords"],
        populate: {
            wide_img: {
                fields: ["alternativeText", "formats"]
            },
            listingSeo: {
                fields: ["description"]
            }
        }
    },
    logo: {
        fields: ["formats"]
    }
}

export async function getResortSeoBySlug(slug: string) {
    const { data } = await fetchRest<[Resort]>(
        "resorts",
        {
            filters: {
                slug: { $eq: slug }
            },
            pagination: {
                limit: 1
            },
            fields: ["short_name"],
            populate: resortSeoPopulate
        },
        undefined,
        60 * 60
    )
    return data?.[0]
}

export async function getResortListingsPageDataBySlug(slug: string) {
    const { data } = await fetchRest<[Pick<Resort, "short_name" | "id" | "resortListingsSEO">]>("resorts", {
        filters: {
            slug: { $eq: slug }
        },
        fields: ["short_name", "id"],
        populate: {
            resortListingsSEO: {
                fields: ["title", "description", "keywords", "content_h1", "content"],
                populate: {
                    wide_img: {
                        fields: ["alternativeText", "formats"]
                    }
                }
            }
        }
    })
    return data[0]
}
